import React, {useEffect, useState, useGlobal} from "reactn";
import {Table, Space, Button, Input, Popconfirm,message} from 'antd';
import moment from 'moment';
import api from '../service/index'
import {Link} from 'react-router-dom';
import qs from 'qs';
import { ImportExcel } from './../components';


import {
    EditOutlined,
    DeleteOutlined,
    PlusOutlined,
    CheckOutlined,
    CloseOutlined,
    Loading3QuartersOutlined
} from "@ant-design/icons";

const Educations = (props) => {

    const {Search} = Input;
    let [data, setData] = useState([]);
    let [totalCount, setTotalCount] = useState(0)
    let [excelData, setExcelData] = useState([]);
    let [selectedRows, setSelectedRows] = useState([])
    let [search, setSearch] = useState(false)
    let [loading, setLoading] = useState(false)
    let [modules] = useGlobal("modules");
    let [isSmall] = useGlobal('isSmall');
    let [languagesCms] = useGlobal("languagesCms"); 

    let path = props.location.pathname.split('/')[1];
    let module
    if (modules !== null) {
        [module] = modules.filter(el => el._id === path);
    }

    let handleTableChange = async (page, filters, sorter) => {
        get(page, filters, sorter);
    }

    let get = async (page, filters, sorter) => {
        if (page) {
            page = {page: page.current, pageSize: page.pageSize}
        } else {
            page = {page: 1, pageSize: 10};
        }
        let shortString = 'order';

        if (sorter) {
            if (Object.keys(sorter).length) {
                shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field
            }
        }

        let _params = {sort: shortString, ...page,};

        if (search.length > 2) {
            _params["search"] = search.toLowerCase();
            _params["searchFields"] = "title";
        }
        let restData = await api.get(`/rest/education?${qs.stringify(_params)}`, {_params}).then(({data}) => {
            if (data.result) {
                setLoading(false)
                setTotalCount(data.result.total);
                return data.result.rows.map((item, key) => {
                    if (item.active)
                        item.active = <CheckOutlined/>;
                    else
                        item.active = <CloseOutlined/>;
                    item.key = key;
                    return item;
                })
            }
        });
        setData(restData);
    }

    useEffect(() => {
        if (modules) {
            setLoading(true)
            get();
        }
    }, [])

    useEffect(() => {
        get();
    }, [search]);

    let rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRows(selectedRows);
        },
        onSelect: (record, selected, selectedRows) => {
            setSelectedRows(selectedRows);
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
            setSelectedRows(selectedRows);
        },
    };


    let deleteRow = async (item_id) => {
        api.delete(`/rest/education/${item_id}`, ({data}) => {
        });
        let newData = data.filter(el => el._id !== item_id);
        setData(newData);
    }
    let deleteSelectedRows = async () => {
        selectedRows.forEach(item => {
            api.delete(`/rest/education/${item._id}`, ({data}) => {
            });
            let newData = data.filter(el => el._id !== item._id);
            setData(newData);
            get();
        })
    }

    let onChange = async (e) => {
        setSearch(e.target.value);
        get();
    }

    let uploadExcel = async () => {
        let code;
    
        let insertCount = 0;
        console.log("excelData     ",excelData)
        if (excelData) {
          for (let i in excelData) {
            code = {
              educationNo: String(excelData[i].educationNo).trim(),
              educationName:excelData[i].educationName.trim(),
              educationGroupCode:String(excelData[i].educationGroupCode).trim(),
            };
    
    
              await api
                .post('/rest/education', code)
                .then(({ data: { result, result_message } }) => {
                        insertCount++;
                });
              
            }
    
        }
        if (insertCount) {
            message.warning(
              'Excel Yüklendi. ' +
                insertCount +
                ' Kayıt Eklendi.',
              2,
            );
          }

      };
      useEffect(() => {
        uploadExcel();
      }, [excelData]);
    let columns = [
        {
      title: "Eğitim Kodu",
            dataIndex: 'educationNo',
            key: 'educationNo',
            sorter: (a, b) => a.educationNo - b.educationNo,
            sortDirections: ['descend', 'ascend']
        },
        {
            title:"Eğitim Adı",
            dataIndex: 'educationName',
            key: 'educationName',
            sorter: (a, b) => a.educationName - b.educationName,
            sortDirections: ['descend', 'ascend']
        },
        {
            title:"Eğitim Grup Kodu",
            dataIndex: 'educationGroupCode',
            key: 'educationGroupCode',
            sorter: (a, b) => a.educationGroupCode - b.educationGroupCode,
            sortDirections: ['descend', 'ascend']
        },
        {
            title: 'Action',
            key: 'action',
            className: 'editColumn',
            width: 150,
            render: (text, record) => (
                <Space size="small">
                    <Link to={"/educations/edit/" + record._id}>
                        <Button icon={<EditOutlined/>}>
                            {isSmall ? '' : languagesCms.EDIT}
                        </Button>
                    </Link>
                    <Popconfirm
                        onConfirm={() => deleteRow(record._id)} title={languagesCms.CHECK_DELETE}
                        okText={languagesCms.OK_TEXT} cancelText={languagesCms.CANCEL_TEXT}>
                        <Button type="danger" icon={<DeleteOutlined/>}>
                            {isSmall ? '' : languagesCms.DELETE}
                        </Button>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    return (
        <div>
            <div className="list-head">
                <div className="list-title">
                    <h1>{module ? module.name : ""}</h1>
                </div>
                <div className="list-buttons">
                {!isSmall && <ImportExcel setExcelData={setExcelData} style={{marginRight: "5px"}}/>}

                    {!isSmall && (
                        <Button type="danger" icon={<DeleteOutlined/>} size="large" onClick={deleteSelectedRows}
                                style={{marginRight: "5px"}}>{languagesCms.DELETE_SELECTED}</Button>
                    )}
                    <Link to="/educations/add">
                        <Button type="light" icon={<PlusOutlined/>} size="large">{isSmall ? '' : languagesCms.ADD_NEW}</Button>
                    </Link>
                </div>
            </div>

            <div className="table-wrap">
                <Search placeholder={"Ara"} onChange={onChange} onSearch={(v) => {
                    setSearch(v);
                    get()
                }}/>
                <Table dataSource={data} columns={columns}
                       loading={{spinning: loading, indicator: <Loading3QuartersOutlined spin/>, size: "large"}}
                       onChange={handleTableChange}
                       pagination={{
                           total: totalCount
                       }}
                       rowSelection={{...rowSelection}}/>
            </div>

        </div>
    );
};


export default Educations;
