import React, { useEffect, useState, useGlobal } from 'reactn';
import { Table, Space, Button, Input, Popconfirm } from 'antd';
import api from '../service/index';
import { Link } from 'react-router-dom';
import qs from 'qs';
import ExportJsonExcel from 'js-export-excel';
import { ImportExcel } from './../components';
import moment from 'moment';
import * as xlsx from 'xlsx';
import { Excel } from "antd-table-saveas-excel";

import {
  EditOutlined,
  DeleteOutlined,
  FileExcelOutlined,
  PlusOutlined,
  CheckOutlined,
  CloseOutlined,
  Loading3QuartersOutlined,
  FileDoneOutlined,
} from '@ant-design/icons';

const SurveysWithMedia = (props) => {
  const { Search } = Input;
  let [data, setData] = useState([]);
  let [totalCount, setTotalCount] = useState(0);
  let [selectedRows, setSelectedRows] = useState([]);
  let [search, setSearch] = useState(false);
  let [loading, setLoading] = useState(false);
  let [isSmall] = useGlobal('isSmall');
  let [langCms] = useGlobal('langCms');
  let [langCode] = useGlobal('langCode');
  let [modules] = useGlobal('modules');
  let [languagesCms] = useGlobal('languagesCms');
  let path = props.location.pathname.split('/')[1];
  let module;
  if (modules !== null) {
    [module] = modules.filter((el) => el._id === path);
  }

  let handleTableChange = async (page, filters, sorter) => {
    get(page, filters, sorter);
  };

  let get = async (page, filters, sorter) => {
    if (page) {
      page = { page: page.current, pageSize: page.pageSize };
    } else {
      page = { page: 1, pageSize: 10 };
    }
    let shortString = 'order';

    if (sorter) {
      if (Object.keys(sorter).length) {
        shortString = (sorter.order === 'descend' ? '-' : '') + sorter.field;
      }
    }

    let _params = { sort: shortString, ...page };

    if (search.length > 2) {
      _params['search'] = search.toLowerCase();
      _params['searchFields'] = 'name';
    }
    let restData = await api
      .get(`/rest/surveysWithMedia?${qs.stringify(_params)}`, { _params })
      .then(({ data }) => {
        if (data.result) {
          setLoading(false);
          setTotalCount(data.result.total);
          return data.result.rows.map((item, key) => {
            if (item.active) item.active = <CheckOutlined />;
            else item.active = <CloseOutlined />;

            if (item.sendNotification)
              item.sendNotification = <CheckOutlined />;
            else item.sendNotification = <CloseOutlined />;

            if (!item.hasOwnProperty('groups')) {
              item.groups = [];
            }

            item.key = key;
            return item;
          });
        }
      });
    setData(restData);
  };

  useEffect(() => {
    if (modules) {
      setLoading(true);
      get();
    }
  }, []);

  useEffect(() => {
    get();
  }, [search]);

  let rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
    },
    onSelect: (record, selected, selectedRows) => {
      setSelectedRows(selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      setSelectedRows(selectedRows);
    },
  };

  let deleteRow = async (item_id) => {
    api.delete(`/rest/surveysWithMedia/${item_id}`, ({ data }) => {});
    let newData = data.filter((el) => el._id !== item_id);
    setData(newData);
  };
  let deleteSelectedRows = async () => {
    selectedRows.forEach((item) => {
      api.delete(`/rest/surveysWithMedia/${item._id}`, ({ data }) => {});
      let newData = data.filter((el) => el._id !== item._id);
      setData(newData);
      get();
    });
  };

  let getReport = async (record) => {
    let lang = langCode ? langCode : langCms[0].code;
    let result = await api.get(
      `/rest/reports/surveysWithMedia?id=${record._id}&lang=${lang}`,
    );
    const file = new Blob(['\ufeff', result.data]);
    let _url = window.URL.createObjectURL(file);
    let a = document.createElement('a');
    a.href = _url;
    a.download = `${record.name.replace(/ /g, '')}_report.xls`;
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  let getReportPersonEducation = async (record) => {
    let lang = langCode ? langCode : langCms[0].code;
    let result = await api.get(
      `/rest/reports/surveysWithMediaPersonEducation?id=${record._id}&lang=${lang}`,
    );
    const file = new Blob(['\ufeff', result.data]);
    let _url = window.URL.createObjectURL(file);
    let a = document.createElement('a');
    a.href = _url;
    a.download = `Çalışan Eğitim Aktarımı_report.xls`;
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  let getReportEducation = async (record) => {
    let lang = langCode ? langCode : langCms[0].code;
    let result = await api.get(
      `/rest/reports/surveysWithMediaEducation?id=${record._id}&lang=${lang}`,
    );
    const file = new Blob(['\ufeff', result.data]);
    let _url = window.URL.createObjectURL(file);
    let a = document.createElement('a');
    a.href = _url;
    a.download = `Eğitim Planlama Aktarımı_report.xls`;
    document.body.appendChild(a);
    a.click();
    a.remove();
  };
  let onChange = async (e) => {
    setSearch(e.target.value);
    get();
  };
  let personReportExcel = async (record) => {
    try {
        let personReport = await api.get('/rest/getPersonReport/' + record._id)
            .then(({ data: { result } }) => {
                return result;
            });

            console.log("personReport    ",personReport)
            const workbook = xlsx.utils.book_new(); // Yeni bir workbook oluştur
            const worksheet = xlsx.utils.json_to_sheet(personReport); // JSON veriyi Excel formatına dönüştür
            const range = xlsx.utils.decode_range(worksheet['!ref']); // Mevcut aralığı al
            for (let col = range.s.c; col <= range.e.c; col++) { // Sütunlar üzerinde döngü
              const cellAddress = xlsx.utils.encode_cell({ r: 0, c: col }); // Başlık hücresinin adresini al
              const cell = worksheet[cellAddress]; // Hücreyi al
            
              if (cell) {
                cell.t = 's'; // Metin tipi
                cell.v = String(cell.v); // Değerini metin olarak ayarla
                cell.z = '@'; // Metin formatı
              }
            }
            for (let row = range.s.r + 1; row <= range.e.r; row++) {     // A kolonu (0. index)
              const cellAddressA = xlsx.utils.encode_cell({ r: row, c: 0 });
              const cellA = worksheet[cellAddressA];
              if (cellA) {
                cellA.t = 's'; // Metin tipi
                cellA.v = String(cellA.v); // Değerini metin olarak ayarla
                cellA.z = '@'; // Metin formatı
              }
          
              // B kolonu (1. index)
              const cellAddressB = xlsx.utils.encode_cell({ r: row, c: 1 });
              const cellB = worksheet[cellAddressB];
              if (cellB) {
                cellB.t = 's'; // Metin tipi
                cellB.v = String(cellB.v); // Değerini metin olarak ayarla
                cellB.z = '@'; // Metin formatı
              }

              const cellAddressC = xlsx.utils.encode_cell({ r: row, c: 2 });
              const cellC = worksheet[cellAddressC];
              if (cellC) {
               const dateStringC = cellC.v; // Tarih dizesini al
         
               // moment ile tarih dizesini parse et
               const momentDateC = moment(dateStringC, "DD.MM.YYYY HH:mm:ss");
               if (momentDateC.isValid()) {
                 const formattedDate = momentDateC.format("DD.MM.YYYY HH:mm:ss"); // İstenen formatta dize olarak formatla
                 cellC.t = "d"; // Hücreyi metin tipi olarak ayarla
                 cellC.v = momentDateC; // Hücre değerini formatlanmış tarih dizesi olarak ayarla
               } else {
                 console.error("Geçersiz tarih formatı: ", dateStringC);
               }
             
              }
              const cellAddressE = xlsx.utils.encode_cell({ r: row, c: 3 });
              const cellE = worksheet[cellAddressE];
              if (cellE) {
                cellE.t = 's'; // Metin tipi
                cellE.v = String(cellE.v); // Değerini metin olarak ayarla
                cellE.z = '@'; // Metin formatı
              }
              const cellAddressD = xlsx.utils.encode_cell({ r: row, c: 4 });
              const cellD = worksheet[cellAddressD];
              if (cellD) {
               const dateString = cellD.v; // Tarih dizesini al
         
               // moment ile tarih dizesini parse et
               const momentDate = moment(dateString, "DD.MM.YYYY HH:mm:ss");
               if (momentDate.isValid()) {
                 const formattedDate = momentDate.format("DD.MM.YYYY HH:mm:ss"); // İstenen formatta dize olarak formatla
                 cellD.t = "d"; // Hücreyi metin tipi olarak ayarla
                 cellD.v = momentDate; // Hücre değerini formatlanmış tarih dizesi olarak ayarla
               } else {
                 console.error("Geçersiz tarih formatı: ", dateString);
               }
             
              }

         

         
              const cellAddressF = xlsx.utils.encode_cell({ r: row, c: 5 });
              const cellF = worksheet[cellAddressF];
              if (cellF) {
                cellF.t = 's'; // Metin tipi
                cellF.v = String(cellF.v); // Değerini metin olarak ayarla
                cellF.z = '@'; // Metin formatı
              }
         
              const cellAddressG = xlsx.utils.encode_cell({ r: row, c: 6 });
              const cellG = worksheet[cellAddressG];
              if (cellG) {
               cellG.t = 's'; // Metin tipi
               cellG.v = String(cellG.v); // Değerini metin olarak ayarla
               cellG.z = '@'; // Metin formatı
              }
         
            }
            xlsx.utils.book_append_sheet(workbook, worksheet, 'Report'); // Sheet'i workbook'a ekle
        xlsx.writeFile(workbook, 'Çalışan Eğitim Aktarımı.xlsx');
    } catch (error) {
        console.error("Hata:", error);
    }
};
let educationReportExcel = async (record) => {

  var option = {};

  let personReport =   await  api
  .get('/rest/getEducationReport/' + record._id)
  .then(({ data: { result, result_message } }) => {
    return result
  });
  /*const excel = new Excel();
  excel
    .addSheet("Eğitim Planlama Aktarımı")
    .addColumns([
      { title: "EĞİTİM KODU", key: "educationCode", dataIndex: "educationCode", excelRender: (educationCode) => educationCode ? educationCode : "-" },
      { title: "BAŞLANGIÇ TARİHİ", key: "educationStartDate", dataIndex: "educationStartDate" },
      { title: "BİTİŞ TARİHİ", key: "educationEndDate", dataIndex: "educationEndDate" },
      { title: "SINIF", key: "classCode", dataIndex: "classCode", excelRender: (classCode) => classCode ? classCode : "-" },
      { title: "EĞİTİM AÇIKLAMASI", key: "education", dataIndex: "education", excelRender: (education) => education ? education : "-" },
      { title: "EĞİTİM AÇIKLAMASI", key: "education", dataIndex: "education", excelRender: (education) => education ? education : "-" },
      { title: "EĞİTİM AÇIKLAMASI", key: "education", dataIndex: "education", excelRender: (education) => education ? education : "-" },
      { title: "YER", key: "classCode2", dataIndex: "classCode2", excelRender: (classCode2) => classCode2 ? classCode2 : "-" },
      { title: "SÜRE(SAAT)", key: "time", dataIndex: "time", excelRender: (time) => time ? time: "-" },
      { title: "EĞİTİM ŞİRKETİ", key: "educationCompany", dataIndex: "educationCompany", excelRender: (educationCompany) => educationCompany ? educationCompany : "-" },
      { title: "FAALİYET TÜRÜ", key: "activityType", dataIndex: "activityType", excelRender: (activityType) => activityType ? activityType : "-" },
      { title: "EĞİTİM AÇIKLAMASI", key: "education", dataIndex: "education", excelRender: (education) => education ? education : "-" },
      { title: "EĞİTMEN KODU", key: "instructorCode", dataIndex: "instructorCode", excelRender: (instructorCode) => instructorCode ? instructorCode : "-" },
      { title: "EĞİTİM KONU KODU", key: "educationSubjectCode", dataIndex: "educationSubjectCode", excelRender: (educationSubjectCode) => educationSubjectCode ? educationSubjectCode : "-" },
      { title: "EĞİTİM GRUP KODU", key: "educationGroupCode", dataIndex: "educationGroupCode", excelRender: (educationGroupCode) => educationGroupCode ? educationGroupCode : "-" },
      { title: "DÜZENLEYEN SİCİL", key: "registerNo", dataIndex: "registerNo", excelRender: (registerNo) => registerNo ? registerNo : "-" },
      { title: "SÜRE(DAKİKA)", key: "minute", dataIndex: "minute", excelRender: (minute) => minute ? minute: "-" },
    ])
    .addDataSource(personReport, {
      str2Percent: true,
    })
    .saveAs("Eğitim Planlama Aktarımı.xlsx");*/

console.log("personReport    ",personReport)
   const workbook = xlsx.utils.book_new(); // Yeni bir workbook oluştur
   const worksheet = xlsx.utils.json_to_sheet(personReport); // JSON veriyi Excel formatına dönüştür
   const range = xlsx.utils.decode_range(worksheet['!ref']); // Mevcut aralığı al
   for (let col = range.s.c; col <= range.e.c; col++) { // Sütunlar üzerinde döngü
    const cellAddress = xlsx.utils.encode_cell({ r: 0, c: col }); // Başlık hücresinin adresini al
    const cell = worksheet[cellAddress]; // Hücreyi al
  
    if (cell) {
      cell.t = 's'; // Metin tipi
      cell.v = String(cell.v); // Değerini metin olarak ayarla
      cell.z = '@'; // Metin formatı
    }
  }
   for (let row = range.s.r + 1; row <= range.e.r; row++) {     // A kolonu (0. index)
     const cellAddressA = xlsx.utils.encode_cell({ r: row, c: 0 });
     const cellA = worksheet[cellAddressA];
     if (cellA) {
       cellA.t = 's'; // Metin tipi
       cellA.v = String(cellA.v); // Değerini metin olarak ayarla
       cellA.z = '@'; // Metin formatı
     }
 
     // B kolonu (1. index)
     const cellAddressB = xlsx.utils.encode_cell({ r: row, c: 1 });
     const cellB = worksheet[cellAddressB];
     if (cellB) {
      const dateString = cellB.v; // Tarih dizesini al

      // moment ile tarih dizesini parse et
      const momentDate = moment(dateString, "DD.MM.YYYY HH:mm:ss");
      if (momentDate.isValid()) {
        const formattedDate = momentDate.format("DD.MM.YYYY HH:mm:ss"); // İstenen formatta dize olarak formatla
        cellB.t = "d"; // Hücreyi metin tipi olarak ayarla
        cellB.v = momentDate; // Hücre değerini formatlanmış tarih dizesi olarak ayarla
      } else {
        console.error("Geçersiz tarih formatı: ", dateString);
      }
    
     }
     const cellAddressC = xlsx.utils.encode_cell({ r: row, c: 2 });
     const cellC = worksheet[cellAddressC];
     if (cellC) {
      const dateStringC = cellC.v; // Tarih dizesini al

      // moment ile tarih dizesini parse et
      const momentDateC = moment(dateStringC, "DD.MM.YYYY HH:mm:ss");
      if (momentDateC.isValid()) {
        const formattedDate = momentDateC.format("DD.MM.YYYY HH:mm:ss"); // İstenen formatta dize olarak formatla
        cellC.t = "d"; // Hücreyi metin tipi olarak ayarla
        cellC.v = momentDateC; // Hücre değerini formatlanmış tarih dizesi olarak ayarla
      } else {
        console.error("Geçersiz tarih formatı: ", dateStringC);
      }
    
     }

     const cellAddressD = xlsx.utils.encode_cell({ r: row, c: 3 });
     const cellD = worksheet[cellAddressD];
     if (cellD) {
       cellD.t = 's'; // Metin tipi
       cellD.v = String(cellD.v); // Değerini metin olarak ayarla
       cellD.z = '@'; // Metin formatı
     }

     const cellAddressE = xlsx.utils.encode_cell({ r: row, c: 4 });
     const cellE = worksheet[cellAddressE];
     if (cellE) {
       cellE.t = 's'; // Metin tipi
       cellE.v = String(cellE.v); // Değerini metin olarak ayarla
       cellE.z = '@'; // Metin formatı
     }

     const cellAddressF = xlsx.utils.encode_cell({ r: row, c: 5 });
     const cellF = worksheet[cellAddressF];
     if (cellF) {
       cellF.t = 's'; // Metin tipi
       cellF.v = String(cellF.v); // Değerini metin olarak ayarla
       cellF.z = '@'; // Metin formatı
     }

     const cellAddressG = xlsx.utils.encode_cell({ r: row, c: 6 });
     const cellG = worksheet[cellAddressG];
     if (cellG) {
      cellG.t = 's'; // Metin tipi
      cellG.v = String(cellG.v); // Değerini metin olarak ayarla
      cellG.z = '@'; // Metin formatı
     }

     const cellAddressH = xlsx.utils.encode_cell({ r: row, c: 7 });
     const cellH = worksheet[cellAddressH];
     if (cellH) {
      cellH.t = 's'; // Metin tipi
      cellH.v = String(cellH.v); // Değerini metin olarak ayarla
      cellH.z = '@'; // Metin formatı
     }

     const cellAddressI = xlsx.utils.encode_cell({ r: row, c: 8 });
     const cellI = worksheet[cellAddressI];
     if (cellI) {
      cellI.t = 's'; // Metin tipi
      cellI.v = String(cellI.v); // Değerini metin olarak ayarla
      cellI.z = '@'; // Metin formatı
     }
     const cellAddressJ = xlsx.utils.encode_cell({ r: row, c: 9 });
     const cellJ = worksheet[cellAddressJ];
     if (cellJ) {
      cellJ.t = 's'; // Metin tipi
      cellJ.v = String(cellJ.v); // Değerini metin olarak ayarla
      cellJ.z = '@'; // Metin formatı
     }
     const cellAddressK = xlsx.utils.encode_cell({ r: row, c: 10 });
     const cellK = worksheet[cellAddressK];
     if (cellK) {
      cellK.t = 's'; // Metin tipi
      cellK.v = String(cellK.v); // Değerini metin olarak ayarla
      cellK.z = '@'; // Metin formatı
     }
     const cellAddressL = xlsx.utils.encode_cell({ r: row, c: 11 });
     const cellL = worksheet[cellAddressL];
     if (cellL) {
      cellL.t = 's'; // Metin tipi
      cellL.v = String(cellL.v); // Değerini metin olarak ayarla
      cellL.z = '@'; // Metin formatı
     }
     const cellAddressM = xlsx.utils.encode_cell({ r: row, c: 12 });
     const cellM = worksheet[cellAddressM];
     if (cellM) {
      cellM.t = 's'; // Metin tipi
      cellM.v = String(cellM.v); // Değerini metin olarak ayarla
      cellM.z = '@'; // Metin formatı
     }
     const cellAddressN = xlsx.utils.encode_cell({ r: row, c: 13 });
     const cellN = worksheet[cellAddressN];
     if (cellN) {
      cellN.t = 's'; // Metin tipi
      cellN.v = String(cellN.v); // Değerini metin olarak ayarla
      cellN.z = '@'; // Metin formatı
     }
     const cellAddressO = xlsx.utils.encode_cell({ r: row, c: 14 });
     const cellO = worksheet[cellAddressO];
     if (cellO) {
      cellO.t = 's'; // Metin tipi
      cellO.v = String(cellO.v); // Değerini metin olarak ayarla
      cellO.z = '@'; // Metin formatı
     }
     const cellAddressP = xlsx.utils.encode_cell({ r: row, c: 15 });
     const cellP = worksheet[cellAddressP];
     if (cellP) {
      cellP.t = 's'; // Metin tipi
      cellP.v = String(cellP.v); // Değerini metin olarak ayarla
      cellP.z = '@'; // Metin formatı
     }
     const cellAddressQ = xlsx.utils.encode_cell({ r: row, c: 16 });
     const cellQ = worksheet[cellAddressQ];
     if (cellQ) {
      cellQ.t = 's'; // Metin tipi
      cellQ.v = String(cellQ.v); // Değerini metin olarak ayarla
      cellQ.z = '@'; // Metin formatı
     }
   }
   xlsx.utils.book_append_sheet(workbook, worksheet, 'Report'); // Sheet'i workbook'a ekle
   xlsx.writeFile(workbook, 'Eğitim Planlama Aktarımı.xlsx');
}
  let columns = [
    {
      title: languagesCms.TITLE,
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name - b.name,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: languagesCms.LANG,
      dataIndex: 'lang',
      key: 'lang',
      sorter: (a, b) => a.lang - b.lang,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: languagesCms.ACTIVE,
      dataIndex: 'active',
      key: 'active',
      sorter: (a, b) => a.active - b.active,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: languagesCms.NOTIFICATION,
      dataIndex: 'sendNotification',
      key: 'sendNotification',
      sorter: (a, b) => a.sendNotification - b.sendNotification,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: languagesCms.ORDER,
      dataIndex: 'order',
      key: 'order',
      sorter: (a, b) => a.order - b.order,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: languagesCms.GROUP,
      dataIndex: 'groups',
      key: 'groups',
      sorter: (a, b) => a.groups - b.groups,
      sortDirections: ['descend', 'ascend'],
      render: (user) =>
        user.length > 0 &&
        user.map((e) => <div style={{ marginBottom: 5 }}>{e.name}</div>),
    },
    {
      title: 'Action',
      key: 'action',
      className: 'editColumn',
      width: 150,
      render: (text, record) => (
        <Space size="small">
          {/* <Button
            type="primary"
            icon={<FileExcelOutlined />}
            onClick={() => getReportPersonEducation(record)}
          >
            {"Çalışan Eğitim Aktarımı"}
          </Button>
          <Button
            type="primary"
            icon={<FileExcelOutlined />}
            onClick={() => getReportEducation(record)}
          >
            {"Eğitim Planlama Aktarımı"}
          </Button> */}
           <Button
            type="primary"
            icon={<FileExcelOutlined />}
            onClick={() => personReportExcel(record)}
          >
            {isSmall ? '' : "Çalışan Eğitim Aktarımı"}
          </Button> 
          <Button
            type="primary"
            icon={<FileExcelOutlined />}
            onClick={() => educationReportExcel(record)}
          >
            {isSmall ? '' : "Eğitim Planlama Aktarımı"}
          </Button> 
          <Link to={'/surveysWithMedia/answers/' + record._id}>
            <Button icon={<FileDoneOutlined />}>
              {isSmall ? '' : languagesCms.ANSWERS}
            </Button>
          </Link>
          <Link to={'/surveysWithMedia/edit/' + record._id}>
            <Button icon={<EditOutlined />}>
              {isSmall ? '' : languagesCms.EDIT}
            </Button>
          </Link>
          <Popconfirm
            onConfirm={() => deleteRow(record._id)}
            title={languagesCms.CHECK_DELETE}
            okText={languagesCms.OK_TEXT}
            cancelText={languagesCms.CANCEL_TEXT}
          >
            <Button type="danger" icon={<DeleteOutlined />}>
              {isSmall ? '' : languagesCms.DELETE}
            </Button>
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>{module ? module.name : ''}</h1>
        </div>
        <div className="list-buttons">
          {!isSmall && (
            <Button
              type="danger"
              icon={<DeleteOutlined />}
              size="large"
              onClick={deleteSelectedRows}
              style={{ marginRight: '5px' }}
            >
              {languagesCms.DELETE_SELECTED}
            </Button>
          )}
          <Link to="/surveysWithMedia/add">
            <Button type="light" icon={<PlusOutlined />} size="large">
              {isSmall ? '' : languagesCms.ADD_NEW}
            </Button>
          </Link>
        </div>
      </div>
      <div className="table-wrap">
        <Search
          placeholder={languagesCms.SEARCH_SURVEY}
          onChange={onChange}
          onSearch={(v) => {
            setSearch(v);
            get();
          }}
        />
        <Table
          dataSource={data}
          columns={columns}
          loading={{
            spinning: loading,
            indicator: <Loading3QuartersOutlined spin />,
            size: 'large',
          }}
          onChange={handleTableChange}
          pagination={{
            total: totalCount,
          }}
          rowSelection={{ ...rowSelection }}
        />
      </div>
    </div>
  );
};

export default SurveysWithMedia;
