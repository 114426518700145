import React, { useEffect, useState, useGlobal } from "reactn";

import { useParams } from 'react-router-dom'
import moment from 'moment';
import { Row, Col, Form, Input, Button, Switch, DatePicker, Card, message, InputNumber, Alert } from 'antd';
import { useHistory } from 'react-router-dom';
import { LeftOutlined } from "@ant-design/icons";
import { FileInputSingle, ModulePicker, LangPicker, ItemByModulePicker,GroupPicker } from '../components';

import api from '../service/index'
import { Link } from 'react-router-dom';
import { useCallback } from "react";


const InstructorsDetail = (props) => {
  let params = useParams()
  let history = useHistory()
  let id = params.id !== "add" ? params.id : false;
  let [languagesCms] = useGlobal("languagesCms"); 
  let [langCode] = useGlobal("langCode");
  let [langCms] = useGlobal("langCms");

  let nowDate = new Date();
  let newRecord = {
    instructorName:"",
    instructorNo:""
  }

  let [data, setData] = useState(id ? [] : newRecord);
  let [errors, setErrors] = useState([]);
  let [moduleChange, setModuleChange] = useState(false);
  let [loading, setLoading] = useState(id ? true : false);
  let [validationCheck, ] = useState(false)

  let [modules] = useGlobal("modules");
  let path = props.location.pathname.split('/')[1];
  let module
  if (modules !== null) {
    [module] = modules.filter(el => el._id === path);
  }

  //onetime run
  useEffect(() => {
    if (modules && id) {
      setLoading(true)
      api.get("/rest/instructor/" + id).then(({ data: { result, result_message } }) => {
        setData(result);
        setLoading(false)
      });
    }
  }, [id]);

  let validate = useCallback(() => {
    let errors = {};


    errors.all = Object.getOwnPropertyNames(errors).map(n => errors[n]);
    errors.hasError = errors.all.length > 0;
    return errors;
  }, [data]);

  useEffect(() => { if (validationCheck) setErrors(validate()) }, [validationCheck, data, validate]);


  let save = async () => {
    let err = validate()
    if (err.hasError) {
      setErrors(err)
      window.scrollTo({ top: 20, behavior: 'smooth' });
    }
    else {
      if (id) {
        api.put("/rest/instructor/" + id, data).then(({ data: { result, result_message } }) => {
          if (result_message.type === 'success') {
            message.success(languagesCms.SUCCESS_EDIT_RECORD, 2);
            history.push('/instructors')
          }
          else
            message.error(languagesCms.ERROR_SAVE_RECORD, 2);
        })
      } else {
        api.post("/rest/instructor", data).then(({ data: { result, result_message } }) => {
          if (result_message.type === 'success') {
            message.success(languagesCms.SUCCESS_SAVE_RECORD, 2);
            history.push('/instructors')
          } else {
            message.error(result_message.message, 2);
          }
        })
      }
    }
  };

  return (
    <div>
      <div className="list-head">
        <div className="list-title">
          <h1>{module ? module.name : ""}</h1>
        </div>
        <div className="list-buttons">
          <Link to="/banners">
            <Button type="light" icon={<LeftOutlined />} size="large">{languagesCms.BACK}</Button>
          </Link>
        </div>
      </div>
      <div className="form-wrap">
        <Card title={id ? languagesCms.EDIT : languagesCms.ADD_NEW} loading={loading}>
          <Form layout="horizontal" size={"large"} onFinish={save}>

            <Row direction="row">
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label={  "Eğitmen Kodu"} help={errors.instructorNo} validateStatus={errors.instructorNo ? 'error' : 'success'}>
                  <Input name="instructorNo" value={data.instructorNo} onChange={e => setData({ ...data, instructorNo: e.target.value })} />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} md={{ span: 12 }}>
                <Form.Item label={  "Eğitmen Adı"} help={errors.instructorName} validateStatus={errors.instructorName ? 'error' : 'success'}>
                  <Input name="instructorName" value={data.instructorName} onChange={e => setData({ ...data, instructorName: e.target.value })} />
                </Form.Item>
              </Col>
            </Row>

            <Row direction="row">
              <Col span={24}>
                <Form.Item>
                  <Button type="primary" disabled={loading} htmlType="submit" size="large" block > {languagesCms.SAVE} </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    </div>
  );
};



export default InstructorsDetail;
