import React, { useEffect, useGlobal } from "reactn";
import { Route, Switch } from 'react-router-dom';
import { Layout } from 'antd';
import SideMenu from './layout/SideMenu';
import HeaderBar from './layout/HeaderBar';
import { env } from "./app.config";

import {
  Dashboard,
  UserList,
  RedirectComp,
  PostWall,
  PostWallDetail,
  Survey,
  SurveyDetail,
  SurveyAnswers,
  SurveyAnswersDetail,
  SurveyDetailPrint,
  SurveyWithMediaDetailPrint,
  SurveyWithMedia2DetailPrint,
  SurveysWithMedia,
  SurveysWithMedia2,
  SurveysWithMediaDetail,
  SurveysWithMedia2Detail,
  SurveysWithMediaAnswers,
  SurveysWithMediaAnswersDetail,
  SurveysWithMediaAnswersDetailPrint,
  SurveysWithMedia2Answers,
  SurveysWithMedia2AnswersDetail,
  SurveysWithMedia2AnswersDetailPrint,
  ShakeWinItem,
  ShakeWinItemDetail,
  ShakeWinCategory,
  ShakeWinCategoryDetail,
  Competitions,
  CompetitionsDetail,
  CompetitionAnswers,
  CompetitionAnswersDetail,
  Event,
  EventDetail,
  Coordinate,
  CoordinateDetail,
  Point,
  PointDetail,
  News,
  NewsDetail,
  Meeting,
  MeetingDetail,
  Campaing,
  CampaingDetail,
  Information,
  InformationDetail,
  InformationSubList,
  InformationSubDetail,
  Topic,
  TopicDetail,
  Education,
  EducationDetail,
  Educations,
  EducationsDetail,
  ServiceRoute,
  ServiceRouteDetail,
  PersonalityInventory,
  PersonalityInventoryDetail,
  SubTopic,
  SubTopicDetail,
  Storie,
  StorieDetail,
  PushNotification,
  PushNotificationDetail,
  BusinessCategory,
  BusinessCategoryDetail,
  Banner,
  BannerDetail,
  Attendee,
  AttendeeDetail,
  BloodNeeds,
  BloodNeedsDetail,
  AttendeeGroup,
  AttendeeGroupDetail,
  AttendeeGroupList,
  AttendeeGroupModules,
  EducationSurveyTemplate,
  EducationSurveyTemplateDetail,
  Lesson,
  LessonDetail,
  PhotoAlbum,
  PhotoAlbumDetail,
  PhotoAlbumItemList,
  PhotoAlbumItemDetail,
  PhotoAlbumItemDetailMulti,
  VideoAlbum,
  VideoAlbumDetail,
  VideoAlbumItemList,
  VideoAlbumItemDetail,
  Sessions,
  SessionsDetail,
  SessionsItemList,
  SessionsItemDetail,
  OurNews,
  OurNewsDetail,
  ActivityCategory,
  ActivityCategoryDetail,
  Activity,
  ActivityDetail,
  ActivityAttendeeList,
  Speaker,
  SpeakerDetail,
  SpeakerOrder,
  SpeakerOrderDetail,
  Sponsor,
  SponsorDetail,
  OpenPositions,
  OpenPositionsDetail,
  Place,
  PlaceDetail,
  Plan,
  PlanDetail,
  PlanCoordinates,
  PlanReservation,
  PlanReservationDetail,
  PlaceReport,
  PlanReservationReport,
  Logs,
  PodcastItems,
  PodcastItemsDetail,
  Podcasts,
  PodcastsDetail,
  Secondhand,
  SecondhandDetail,
  Contact,
  ContactDetail,
  NotFoundPage,
  Barcod,
  Like,
  Comment,
  CommentDetail,
  Info6,
  Info6Detail,
  Info6SubList,
  Info6SubDetail,
  EmailSender,
  EmailSenderDetail,
  IsbankAttendee,
  IsbankAttendeeDetail,
  SessionOrderCategory,
  SessionOrderCategoryDetail,
  SessionOrderSubCategory,
  SessionOrderSubCategoryDetail,
  SessionOrderItem,
  SessionOrderItemDetail,
  StorieLike,
  StorieComment,
  StorieCommentDetail,
  SurveyAnswersDetailPrint,
  Instructors,
  InstructorsDetail
} from './pages';

import Setting from './pages/Settings/Setting';
import Module from './pages/Settings/Module';
import ModuleDetail from './pages/Settings/ModuleDetail';
import Customization from './pages/Settings/Customization';
import LoginSetting from './pages/Settings/LoginSetting';
import SurveyWithMediaIcons from './pages/Settings/SurveyWithMediaIcons';
import Version from './pages/Settings/Version';
import TopRightModule from './pages/Settings/TopRightModule';
import TopLeftModule from './pages/Settings/TopLeftModule';
import SmarteventSetting from './pages/Settings/SmarteventSetting';
import Deneme from './pages/Settings/Deneme';
import DenemeDetail from './pages/Settings/DenemeDetail';
import BottomBar from './pages/Settings/BottomBar';
import BottomBarDetail from './pages/Settings/BottomBarDetail';
import Languages from './pages/Settings/Languages';
import LanguagesDetail from './pages/Settings/LanguagesDetail';
import ProfilSettingsMenu from './pages/Settings/ProfilSettingsMenu';
import ProfilSettingsMenuDetail from './pages/Settings/ProfilSettingsMenuDetail';
import LikeTypes from './pages/Settings/LikeTypes';
import LikeTypesDetail from './pages/Settings/LikeTypesDetail';
import CoordinateTypes from './pages/Settings/CoordinateTypes';
import CoordinateTypesDetail from './pages/Settings/CoordinateTypesDetail';
import CoordinateMenu from './pages/Settings/CoordinateMenu';
import CoordinateMenuDetail from './pages/Settings/CoordinateMenuDetail';
import PointTypes from './pages/Settings/PointTypes';
import PointTypesDetail from './pages/Settings/PointTypesDetail';
import PointMenu from './pages/Settings/PointMenu';
import PointMenuDetail from './pages/Settings/PointMenuDetail';
import SupportedLang from './pages/Settings/SupportedLang';
import SupportedLangDetail from './pages/Settings/SupportedLangDetail';
import Live from './pages/Settings/Live';
import Nword from './pages/Settings/Nword';
import Nword2 from './pages/Settings/Nword2';
import WebApp from './pages/Settings/WebApp';
import WebAppDetail from './pages/Settings/WebAppDetail';
import PofileSettings from './pages/Settings/PofileSettings';
import AppControls from './pages/Settings/AppControls';
import HealthList from './pages/Settings/HealthList';
import ShakeWinMessages from './pages/Settings/ShakeWinMessages';
import ShakeWinMessagesDetail from './pages/Settings/ShakeWinMessagesDetail';
import ResultMessages from './pages/Settings/ResultMessages';
import ResultMessagesDetail from './pages/Settings/ResultMessagesDetail';
import SmsAndEmailMessage from './pages/Settings/SmsAndEmailMessage';
import SmsAndEmailMessageDetail from './pages/Settings/SmsAndEmailMessageDetail';
import PostwallSettings from './pages/Settings/PostwallSettings';
import TopRightChatModule from './pages/Settings/TopRightChatModule';
import BirthdaySettings from './pages/Settings/BirthdaySettings';
import JobStartDateSettings from './pages/Settings/JobStartDateSettings';
import ReservationSettings from './pages/Settings/ReservationSettings';
import ReservationSettingsBranch from './pages/Settings/ReservationSettingsBranch';
import PageReservationSettings from './pages/Settings/PageReservationSettings';
import PageReservationSettingsBranch from './pages/Settings/PageReservationSettingsBranch';
import TopRightShareButton from './pages/Settings/TopRightShareButton';
import PublicHolidays from './pages/Settings/PublicHolidays';
import LanguagesCms from './pages/Settings/LanguagesCms';
import LanguagesCmsDetail from './pages/Settings/LanguagesCmsDetail';
import PublicHolidaysDetail from './pages/Settings/PublicHolidaysDetail';
import PagePublicHolidays from './pages/Settings/PagePublicHolidays';
import PagePublicHolidaysDetail from './pages/Settings/PagePublicHolidaysDetail';
import ReservationAllSettings from './pages/Settings/ReservationAllSettings';
import PageReservationDepartments from './pages/Settings/PageReservationDepartments';
import PageReservationDepartmentsDetail from './pages/Settings/PageReservationDepartmentsDetail';
import ReservationKVKKSettings from './pages/Settings/ReservationKVKKSettings';
import PageReservationKVKKSettings from './pages/Settings/PageReservationKVKKSettings';

import Threats from './pages/Theats';

const { Header, Content } = Layout;

const Admin = (props) => {
  let [token, setToken] = useGlobal('token');
  let [user] = useGlobal('user');
  let [isSmall, setIsSmall] = useGlobal('isSmall')
  let [collapsed, setCollapsed] = useGlobal('collapsed');

  useEffect(() => {
    window.onresize = () => {
      setIsSmall(window.innerWidth < 1024)
    }
  }, [setIsSmall, setToken])
  useEffect(() => {
    if (isSmall)
      setCollapsed(true)
    else
      setCollapsed(false)
  }, [isSmall, setCollapsed])

  if (!token || !user) {
    props.history.push('/login');
  }

  return (
    <Switch>
      <Route path={["/surveysWithMediaPrint"]}>
        <Layout style={{ minHeight: '100vh' }}>
          <Content >
            <Route exact path="/surveysWithMediaPrint/:surveyId/detail/:id" component={SurveysWithMediaAnswersDetailPrint} />
          </Content>
        </Layout>
      </Route>

      <Route path={["/surveyPrint"]}>
        <Layout style={{ minHeight: '100vh' }}>
          <Content >
            <Route exact path="/surveyPrint/:surveyId/detail/:id" component={SurveyAnswersDetailPrint} />
          </Content>
        </Layout>
      </Route>

      <Route path={["/surveysWithMedia2Print"]}>
        <Layout style={{ minHeight: '100vh' }}>
          <Content >
            <Route exact path="/surveysWithMedia2Print/:surveyId/detail/:id" component={SurveysWithMedia2AnswersDetailPrint} />
          </Content>
        </Layout>
      </Route>

      <Route path={["/SurveyDetailPrint"]}>
        <Layout style={{ minHeight: '100vh' }}>
          <Content >
            <Route exact path="/SurveyDetailPrint/:surveyId/question/:name" component={SurveyDetailPrint} />
          </Content>
        </Layout>
      </Route>

      <Route path={["/SurveyWithMediaDetailPrint"]}>
        <Layout style={{ minHeight: '100vh' }}>
          <Content >
            <Route exact path="/SurveyWithMediaDetailPrint/:surveyId/question/:name" component={SurveyWithMediaDetailPrint} />
          </Content>
        </Layout>
      </Route>

      <Route path={["/SurveyWithMedia2DetailPrint"]}>
        <Layout style={{ minHeight: '100vh' }}>
          <Content >
            <Route exact path="/SurveyWithMedia2DetailPrint/:surveyId/question/:name" component={SurveyWithMedia2DetailPrint} />
          </Content>
        </Layout>
      </Route>

      <Route>
      <Layout>
        <SideMenu collapsed={collapsed} />
        <Layout className="site-layout" style={{ minHeight: '100vh' }}>
          <Header
            className="site-layout-background header"
            style={{ padding: '0 20px' }}
            theme="dark"
          >
            <HeaderBar />
          </Header>

          <Content className="main-wrap">
            {token && (
                <>
            <Switch>

                  <Route exact path="/" component={RedirectComp} />
                  <Route exact path="/adminInfo" component={Dashboard} />

                  <Route path="/userlist" component={UserList} />
                  <Route path="/threats" component={Threats} />


                  {/* Settings */}
                  <Route exact path="/settings" component={Setting} />
                  <Route exact path="/modules" component={Module} />
                  <Route exact path="/modules/:id" component={ModuleDetail} />
                  <Route exact path="/modules/detail/:id" component={ModuleDetail} />
                  <Route exact path="/bottomBar" component={BottomBar} />
                  <Route exact path="/bottomBar/:id" component={BottomBarDetail} />
                  <Route exact path="/languages" component={Languages} />
                  <Route exact path="/languages/:id" component={LanguagesDetail} />
                  <Route exact path="/profilSettingsMenu" component={ProfilSettingsMenu} />
                  <Route exact path="/profilSettingsMenu/:id" component={ProfilSettingsMenuDetail} />
                  <Route exact path="/coordinateTypes" component={CoordinateTypes} />
                  <Route exact path="/coordinateTypes/:id" component={CoordinateTypesDetail} />
                  <Route exact path="/pointTypes" component={PointTypes} />
                  <Route exact path="/pointTypes/:id" component={PointTypesDetail} />
                  <Route exact path="/shakeWinMessages" component={ShakeWinMessages} />
                  <Route exact path="/shakeWinMessages/:id" component={ShakeWinMessagesDetail} />
                  <Route exact path="/resultMessages" component={ResultMessages} />
                  <Route exact path="/resultMessages/:id" component={ResultMessagesDetail} />
                  <Route exact path="/settings/customization" component={Customization} />
                  <Route exact path="/settings/login_setting" component={LoginSetting} />
                  <Route exact path="/settings/surveyWithMediaIcons" component={SurveyWithMediaIcons} />
                  <Route exact path="/settings/smatevent_settings" component={SmarteventSetting} />
                  <Route exact path="/settings/version" component={Version} />
                  <Route exact path="/settings/topRightModule" component={TopRightModule} />
                  <Route exact path="/settings/topLeftModule" component={TopLeftModule} />
                  <Route exact path="/settings/deneme" component={Deneme} />
                  <Route exact path="/settings/deneme/:id/edit/:subId" component={DenemeDetail} />
                  <Route exact path="/settings/like_types" component={LikeTypes} />
                  <Route exact path="/settings/like_types/:id/edit/:subId" component={LikeTypesDetail} />
                  <Route exact path="/settings/coordinate_menu" component={CoordinateMenu} />
                  <Route exact path="/settings/coordinate_menu/:id/edit/:subId" component={CoordinateMenuDetail} />
                  <Route exact path="/settings/point_menu" component={PointMenu} />
                  <Route exact path="/settings/point_menu/:id/edit/:subId" component={PointMenuDetail} />
                  <Route exact path="/settings/supportedLangs" component={SupportedLang} />
                  <Route exact path="/settings/supportedLangs/:id/edit/:subId" component={SupportedLangDetail} />
                  <Route exact path="/settings/live" component={Live} />
                  <Route exact path="/settings/nword" component={Nword} />
                  <Route exact path="/settings/nword2" component={Nword2} />
                  <Route exact path="/settings/webApp" component={WebApp} />
                  <Route exact path="/settings/languagesCms" component={LanguagesCms} />
                  <Route exact path="/settings/languagesCms/:id" component={LanguagesCmsDetail} />
                  <Route exact path="/settings/webApp/:id/edit/:subId" component={WebAppDetail} />
                  <Route exact path="/settings/profileSettings" component={PofileSettings} />
                  <Route exact path="/settings/appControls" component={AppControls} />
                  <Route exact path="/settings/healthList" component={HealthList} />
                  <Route exact path="/settings/smsAndEmailMessage" component={SmsAndEmailMessage} />
                  <Route exact path="/settings/smsAndEmailMessage/:name/:id/edit/:subId" component={SmsAndEmailMessageDetail} />
                  <Route exact path="/settings/postwallSettings" component={PostwallSettings} />
                  <Route exact path="/settings/topRightChatModule" component={TopRightChatModule} />
                  <Route exact path="/settings/birthdaySettings" component={BirthdaySettings} />
                  <Route exact path="/settings/jobStartDateSettings" component={JobStartDateSettings} />
                  <Route exact path="/settings/reservationSettings" component={ReservationSettings} />
                  <Route exact path="/settings/reservationSettingsBranch" component={ReservationSettingsBranch} />
                  <Route exact path="/settings/pageReservationSettings" component={PageReservationSettings} />
                  <Route exact path="/settings/pageReservationSettingsBranch" component={PageReservationSettingsBranch} />
                  <Route exact path="/settings/topRightShareButton" component={TopRightShareButton} />

                  <Route exact path="/settings/publicHolidays" component={PublicHolidays} />
                  <Route exact path="/settings/publicHolidays/:id/edit/:subId" component={PublicHolidaysDetail} />
                  <Route exact path="/settings/publicHolidays/:id/add" component={PublicHolidaysDetail} />

                  <Route exact path="/settings/pagePublicHolidays" component={PagePublicHolidays} />
                  <Route exact path="/settings/pagePublicHolidays/:id/edit/:subId" component={PagePublicHolidaysDetail} />
                  <Route exact path="/settings/pagePublicHolidays/:id/add" component={PagePublicHolidaysDetail} />

                  <Route exact path="/settings/reservationAllSettings" component={ReservationAllSettings} />
                  <Route exact path="/settings/pageReservationDepartments" component={PageReservationDepartments} />
                  <Route exact path="/settings/pageReservationDepartments/edit/:id" component={PageReservationDepartmentsDetail} />
                  <Route exact path="/settings/pageReservationDepartments/add" component={PageReservationDepartmentsDetail} />

                  <Route exact path="/settings/reservationKVKKSettings" component={ReservationKVKKSettings} />
                  <Route exact path="/settings/pageReservationKVKKSettings" component={PageReservationKVKKSettings} />
                  {/* Settings */}

                  <Route exact path="/postwall" component={PostWall} />
                  <Route exact path="/postwall/edit/:id" component={PostWallDetail} />
                  <Route exact path="/postwall/add" component={PostWallDetail} />


                  <Route exact path="/postwall/Likes/:id" component={Like} />
                  <Route exact path="/postwall/comments/:id" component={Comment} />
                  <Route exact path="/postwall/:postId/comments/:id" component={CommentDetail} />

                  <Route exact path="/survey" component={Survey} />
                  <Route exact path="/survey/edit/:id" component={SurveyDetail} />
                  <Route exact path="/survey/add" component={SurveyDetail} />
                  <Route exact path="/survey/answers/:id" component={SurveyAnswers} />
                  <Route exact path="/survey/:surveyId/detail/:id" component={SurveyAnswersDetail} />

                  <Route exact path="/surveysWithMedia" component={SurveysWithMedia} />
                  <Route exact path="/surveysWithMedia/edit/:id" component={SurveysWithMediaDetail} />
                  <Route exact path="/surveysWithMedia/add" component={SurveysWithMediaDetail} />
                  <Route exact path="/surveysWithMedia/answers/:id" component={SurveysWithMediaAnswers} />
                  <Route exact path="/surveysWithMedia/:surveyId/detail/:id" component={SurveysWithMediaAnswersDetail} />

                  <Route exact path="/surveysWithMedia2" component={SurveysWithMedia2} />
                  <Route exact path="/surveysWithMedia2/edit/:id" component={SurveysWithMedia2Detail} />
                  <Route exact path="/surveysWithMedia2/add" component={SurveysWithMedia2Detail} />
                  <Route exact path="/surveysWithMedia2/answers/:id" component={SurveysWithMedia2Answers} />
                  <Route exact path="/surveysWithMedia2/:surveyId/detail/:id" component={SurveysWithMedia2AnswersDetail} />

                  <Route exact path="/shakeWin" component={ShakeWinCategory} />
                  <Route exact path="/shakeWin/edit/:id" component={ShakeWinCategoryDetail} />
                  <Route exact path="/shakeWin/add" component={ShakeWinCategoryDetail} />

                  <Route exact path="/shakeWin/detail/:id" component={ShakeWinItem} />
                  <Route exact path="/shakeWin/detail/:id/add" component={ShakeWinItemDetail} />
                  <Route exact path="/shakeWin/detail/:id/edit/:subid" component={ShakeWinItemDetail} />

                  <Route exact path="/competitions" component={Competitions} />
                  <Route exact path="/competition/edit/:id" component={CompetitionsDetail} />
                  <Route exact path="/competition/add" component={CompetitionsDetail} />
                  <Route exact path="/competition/answers/:id" component={CompetitionAnswers} />
                  <Route exact path="/competition/:surveyId/detail/:id" component={CompetitionAnswersDetail} />

                  <Route exact path="/events" component={Event} />
                  <Route exact path="/events/edit/:id" component={EventDetail} />
                  <Route exact path="/events/add" component={EventDetail} />


                  <Route exact path="/meetings" component={Meeting} />
                  <Route exact path="/meetings/edit/:id" component={MeetingDetail} />
                  <Route exact path="/meetings/add" component={MeetingDetail} />

                  <Route exact path="/health" component={Coordinate} />
                  <Route exact path="/health/add" component={CoordinateDetail} />
                  <Route exact path="/health/edit/:id" component={CoordinateDetail} />

                  <Route exact path="/point" component={Point} />
                  <Route exact path="/point/add" component={PointDetail} />
                  <Route exact path="/point/edit/:id" component={PointDetail} />

                  <Route exact path="/workfamily" component={Campaing} />
                  <Route exact path="/workfamily/edit/:id" component={CampaingDetail} />
                  <Route exact path="/workfamily/add" component={CampaingDetail} />

                  <Route exact path="/news" component={News} />
                  <Route exact path="/news/edit/:id" component={NewsDetail} />
                  <Route exact path="/news/add" component={NewsDetail} />

                  <Route exact path="/emailSender" component={EmailSender} />
                  <Route exact path="/emailSender/edit/:id" component={EmailSenderDetail} />
                  <Route exact path="/emailSender/add" component={EmailSenderDetail} />

                  <Route exact path="/infos" component={Information} />
                  <Route exact path="/infos/edit/:id" component={InformationDetail} />
                  <Route exact path="/infos/add" component={InformationDetail} />
                  <Route exact path="/infos/detail/:id" component={InformationSubList} />
                  <Route exact path="/infos/detail/:id/edit/:subid" component={InformationSubDetail} />
                  <Route exact path="/infos/detail/:id/add" component={InformationSubDetail} />

                  <Route exact path="/infos2" component={Information} />
                  <Route exact path="/infos2/edit/:id" component={InformationDetail} />
                  <Route exact path="/infos2/add" component={InformationDetail} />
                  <Route exact path="/infos2/detail/:id" component={InformationSubList} />
                  <Route exact path="/infos2/detail/:id/edit/:subid" component={InformationSubDetail} />
                  <Route exact path="/infos2/detail/:id/add" component={InformationSubDetail} />

                  <Route exact path="/infos3" component={Information} />
                  <Route exact path="/infos3/edit/:id" component={InformationDetail} />
                  <Route exact path="/infos3/add" component={InformationDetail} />
                  <Route exact path="/infos3/detail/:id" component={InformationSubList} />
                  <Route exact path="/infos3/detail/:id/edit/:subid" component={InformationSubDetail} />
                  <Route exact path="/infos3/detail/:id/add" component={InformationSubDetail} />


                  <Route exact path="/infos4" component={Information} />
                  <Route exact path="/infos4/edit/:id" component={InformationDetail} />
                  <Route exact path="/infos4/add" component={InformationDetail} />
                  <Route exact path="/infos4/detail/:id" component={InformationSubList} />
                  <Route exact path="/infos4/detail/:id/edit/:subid" component={InformationSubDetail} />
                  <Route exact path="/infos4/detail/:id/add" component={InformationSubDetail} />


                  <Route exact path="/infos5" component={Information} />
                  <Route exact path="/infos5/edit/:id" component={InformationDetail} />
                  <Route exact path="/infos5/add" component={InformationDetail} />
                  <Route exact path="/infos5/detail/:id" component={InformationSubList} />
                  <Route exact path="/infos5/detail/:id/edit/:subid" component={InformationSubDetail} />
                  <Route exact path="/infos5/detail/:id/add" component={InformationSubDetail} />

                  <Route exact path="/infos6" component={Info6} />
                  <Route exact path="/infos6/edit/:id" component={Info6Detail} />
                  <Route exact path="/infos6/add" component={Info6Detail} />
                  <Route exact path="/infos6/detail/:id" component={Info6SubList} />
                  <Route exact path="/infos6/detail/:id/edit/:subid" component={Info6SubDetail} />
                  <Route exact path="/infos6/detail/:id/add" component={Info6SubDetail} />

                  <Route exact path="/infos7" component={Information} />
                  <Route exact path="/infos7/edit/:id" component={InformationDetail} />
                  <Route exact path="/infos7/add" component={InformationDetail} />
                  <Route exact path="/infos7/detail/:id" component={InformationSubList} />
                  <Route exact path="/infos7/detail/:id/edit/:subid" component={InformationSubDetail} />
                  <Route exact path="/infos7/detail/:id/add" component={InformationSubDetail} />


                  <Route exact path="/topics" component={Topic} />
                  <Route exact path="/topics/edit/:id" component={TopicDetail} />
                  <Route exact path="/topics/add" component={TopicDetail} />
                  <Route exact path="/topics/detail/:id" component={SubTopic} />
                  <Route exact path="/topics/detail/:id/edit/:subid" component={SubTopicDetail} />
                  <Route exact path="/topics/detail/:id/add" component={SubTopicDetail} />

                  <Route exact path="/education" component={Education} />
                  <Route exact path="/education/edit/:id" component={EducationDetail} />
                  <Route exact path="/education/add" component={EducationDetail} />

                  <Route exact path="/educations" component={Educations} />
                  <Route exact path="/educations/edit/:id" component={EducationsDetail} />
                  <Route exact path="/educations/add" component={EducationsDetail} />

                  <Route exact path="/instructors" component={Instructors} />
                  <Route exact path="/instructors/edit/:id" component={InstructorsDetail} />
                  <Route exact path="/instructors/add" component={InstructorsDetail} />

                  <Route exact path="/route" component={ServiceRoute} />
                  <Route exact path="/route/edit/:id" component={ServiceRouteDetail} />
                  <Route exact path="/route/add" component={ServiceRouteDetail} />

                  <Route exact path="/personalityInventory" component={PersonalityInventory} />
                  <Route exact path="/personalityInventory/edit/:id" component={PersonalityInventoryDetail} />
                  <Route exact path="/personalityInventory/add" component={PersonalityInventoryDetail} />

                  <Route exact path="/stories" component={Storie} />
                  <Route exact path="/stories/edit/:id" component={StorieDetail} />
                  <Route exact path="/stories/add" component={StorieDetail} />
                  <Route exact path="/stories/:refId/add" component={StorieDetail} />
                  <Route exact path="/stories/likes/:id" component={StorieLike} />
                  <Route exact path="/stories/comments/:id" component={StorieComment} />
                  <Route exact path="/stories/:commentsId/comment/:id" component={StorieCommentDetail} />

                  <Route exact path="/pushNotifications" component={PushNotification} />
                  <Route exact path="/pushNotifications/edit/:id" component={PushNotificationDetail} />
                  <Route exact path="/pushNotifications/add" component={PushNotificationDetail} />

                  <Route exact path="/businessCategories" component={BusinessCategory} />
                  <Route exact path="/businessCategories/edit/:id" component={BusinessCategoryDetail} />
                  <Route exact path="/businessCategories/add" component={BusinessCategoryDetail} />
                  
                  <Route exact path="/banners" component={Banner} />
                  <Route exact path="/banners/edit/:id" component={BannerDetail} />
                  <Route exact path="/banners/add" component={BannerDetail} />
                
                  <Route exact path="/attendeeGroups" component={AttendeeGroup} />
                  <Route exact path="/attendeeGroups/edit/:id" component={AttendeeGroupDetail} />
                  <Route exact path="/attendeeGroups/add" component={AttendeeGroupDetail} />
                  <Route exact path="/attendeeGroups/detail/:id" component={AttendeeGroupList} />
                  <Route exact path="/attendeeGroups/modules/:subid" component={AttendeeGroupModules} />


                  <Route exact path="/attendees" component={env.COMPANY === "ISBANK" ? IsbankAttendee : Attendee} />
                  <Route exact path="/attendees/edit/:id" component={env.COMPANY === "ISBANK" ? IsbankAttendeeDetail : AttendeeDetail} />
                  <Route exact path="/attendees/add" component={AttendeeDetail} />

                  <Route exact path="/educationSurveyTemplates" component={EducationSurveyTemplate} />
                  <Route exact path="/educationSurveyTemplates/edit/:id" component={EducationSurveyTemplateDetail} />
                  <Route exact path="/educationSurveyTemplates/add" component={EducationSurveyTemplateDetail} />

                  <Route exact path="/bloodNeeds" component={BloodNeeds} />
                  <Route exact path="/bloodNeeds/edit/:id" component={BloodNeedsDetail} />
                  <Route exact path="/bloodNeeds/add" component={BloodNeedsDetail} />

                  <Route exact path="/lessons" component={Lesson} />
                  <Route exact path="/lessons/edit/:id" component={LessonDetail} />
                  <Route exact path="/lessons/add" component={LessonDetail} />

                  <Route exact path="/photoAlbum" component={PhotoAlbum} />
                  <Route exact path="/photoAlbum/edit/:id" component={PhotoAlbumDetail} />
                  <Route exact path="/photoAlbum/add" component={PhotoAlbumDetail} />
                  <Route exact path="/photoAlbum/detail/:id" component={PhotoAlbumItemList} />
                  <Route exact path="/photoAlbum/detail/:id/add" component={PhotoAlbumItemDetail} />
                  <Route exact path="/photoAlbum/detail/:id/edit/:subid" component={PhotoAlbumItemDetail} />
                  <Route exact path="/photoAlbum/detail/:id/multi/add" component={PhotoAlbumItemDetailMulti} />

                  <Route exact path="/videoAlbum" component={VideoAlbum} />
                  <Route exact path="/videoAlbum/edit/:id" component={VideoAlbumDetail} />
                  <Route exact path="/videoAlbum/add" component={VideoAlbumDetail} />
                  <Route exact path="/videoAlbum/detail/:id" component={VideoAlbumItemList} />
                  <Route exact path="/videoAlbum/detail/:id/add" component={VideoAlbumItemDetail} />
                  <Route exact path="/videoAlbum/detail/:id/edit/:subid" component={VideoAlbumItemDetail} />

                  <Route exact path="/ourNews" component={OurNews} />
                  <Route exact path="/ourNews/edit/:id" component={OurNewsDetail} />
                  <Route exact path="/ourNews/add" component={OurNewsDetail} />

                  <Route exact path="/activity" component={ActivityCategory} />
                  <Route exact path="/activity/edit/:id" component={ActivityCategoryDetail} />
                  <Route exact path="/activity/add" component={ActivityCategoryDetail} />
                  <Route exact path="/activity/detail/:id" component={Activity} />
                  <Route exact path="/activity/detail/:id/add" component={ActivityDetail} />
                  <Route exact path="/activity/detail/:id/edit/:subid" component={ActivityDetail} />
                  <Route exact path="/activity/attendees/:subid" component={ActivityAttendeeList} />

                  <Route exact path="/speakers" component={Speaker} />
                  <Route exact path="/speakers/edit/:id" component={SpeakerDetail} />
                  <Route exact path="/speakers/add" component={SpeakerDetail} />

                  <Route exact path="/speakersOrder" component={SpeakerOrder} />
                  <Route exact path="/speakersOrder/edit/:id" component={SpeakerOrderDetail} />
                  <Route exact path="/speakersOrder/add" component={SpeakerOrderDetail} />

                  <Route exact path="/sponsors" component={Sponsor} />
                  <Route exact path="/sponsors/edit/:id" component={SponsorDetail} />
                  <Route exact path="/sponsors/add" component={SponsorDetail} />

                  <Route exact path="/sessions" component={Sessions} />
                  <Route exact path="/sessions/edit/:id" component={SessionsDetail} />
                  <Route exact path="/sessions/add" component={SessionsDetail} />
                  <Route exact path="/sessions/detail/:id" component={SessionsItemList} />
                  <Route exact path="/sessions/detail/:id/add" component={SessionsItemDetail} />
                  <Route exact path="/sessions/detail/:id/edit/:subid" component={SessionsItemDetail} />

                  <Route exact path="/openPositions" component={OpenPositions} />
                  <Route exact path="/openPositions/edit/:id" component={OpenPositionsDetail} />
                  <Route exact path="/openPositions/add" component={OpenPositionsDetail} />

                  <Route exact path="/place" component={Place} />
                  <Route exact path="/place/edit/:id" component={PlaceDetail} />
                  <Route exact path="/place/add" component={PlaceDetail} />

                  <Route exact path="/place/:placeId/plan" component={Plan} />
                  <Route exact path="/place/:placeId/plan/edit/:id" component={PlanDetail} />
                  <Route exact path="/place/:placeId/plan/add" component={PlanDetail} />

                  <Route exact path="/place/:placeId/plan/edit/:id/coordinates" component={PlanCoordinates} />
                  <Route exact path="/place/:placeId/plan/edit/:id/reservation" component={PlanReservation} />
                  <Route exact path="/planReservation/:id" component={PlanReservationDetail} />

                  <Route exact path="/placeReport" component={PlaceReport} />
                  <Route exact path="/planReservationReport" component={PlanReservationReport} />

                  
                  <Route exact path="/Logs" component={Logs} />


                  <Route exact path="/podcasts" component={Podcasts} />
                  <Route exact path="/podcasts/edit/:id" component={PodcastsDetail} />
                  <Route exact path="/podcasts/add" component={PodcastsDetail} />
                  <Route exact path="/podcasts/detail/:id" component={PodcastItems} />
                  <Route exact path="/podcasts/detail/:id/add" component={PodcastItemsDetail} />
                  <Route exact path="/podcasts/detail/:id/edit/:subid" component={PodcastItemsDetail} />
                  <Route exact path="/barcod/lesson/:id" component={Barcod} />

                  <Route exact path="/secondhand" component={Secondhand} />
                  <Route exact path="/secondhand/edit/:id" component={SecondhandDetail} />
                  <Route exact path="/secondhand/add" component={SecondhandDetail} />

                  <Route exact path="/contacts" component={Contact} />
                  <Route exact path="/contacts/edit/:id" component={ContactDetail} />
                  <Route exact path="/contacts/add" component={ContactDetail} />

                  <Route exact path="/sessionOrder" component={SessionOrderCategory} />
                  <Route exact path="/sessionOrder/edit/:id" component={SessionOrderCategoryDetail} />
                  <Route exact path="/sessionOrder/add" component={SessionOrderCategoryDetail} />
                  <Route exact path="/sessionOrder/detail/:parentId" component={SessionOrderSubCategory} />
                  <Route exact path="/sessionOrder/:parentId/add" component={SessionOrderSubCategoryDetail} />
                  <Route exact path="/sessionOrder/:parentId/edit/:id" component={SessionOrderSubCategoryDetail} />
                  <Route exact path="/sessionOrder/:parentId/detail/:id" component={SessionOrderItem} />
                  <Route exact path="/sessionOrder/:parentId/edit/:subId/:id" component={SessionOrderItemDetail} />
                  <Route exact path="/sessionOrder/:parentId/detail/:subId/:id" component={SessionOrderItemDetail} />
                  <Route exact path="/sessionOrder/:parentId/add/:subId" component={SessionOrderItemDetail} />
                  <Route path='*' component={NotFoundPage} />
            </Switch>
            </>
              )}

          </Content>
        </Layout>
      </Layout>
      </Route>
    </Switch>
  );
};

export default Admin;
